<template>
    <div class="sticky top-0 z-30 bg-[color:var(--background-color)]"
        :style="{
            '--background-color': infoBarBackground,
        }">
        <div class="flex flex-wrap md:container md:mx-auto">
            <v-header-info-bar v-if="infoBar"
                :story="infoBar"
                class="flex-1 bg-[color:var(--background-color)] text-[color:var(--color)] transition-all duration-300"
                :class="{
                    'h-0 basis-0': isHeaderSticky,
                    'h-10 basis-10': !isHeaderSticky,
                }"
                :style="{
                    '--color': infoBarfontColor
                }" />

            <v-header-info-shop
                :is-header-sticky="isHeaderSticky"
                :background-color="infoBarBackground"
                :font-color="infoBarfontColor" />

            <div class="basis-full">
                <header class="relative inset-x-1/2 mx-[-50vw] w-screen
                    bg-[color:var(--background-color)]
                    px-5 text-[color:var(--color)] md:px-0"
                    :style="{
                        '--background-color': backgroundColor,
                        '--color': fontColor
                    }">
                    <div class="container mx-auto">
                        <div class="flex items-center justify-between">
                            <nav class="flex h-auto items-center justify-items-start space-x-4">
                                <nuxt-link :to="{ name: 'index' }"
                                    tab-index="-1"
                                    :aria-label="$t('aria.open-page', { page: $t('pages.home') })"
                                    class="ml-1.5 mr-3 h-full transition-all duration-300">
                                    <v-icon :icon="headerLogo"
                                        class="text-white transition-all duration-300"
                                        :class="{
                                            'my-[10px] text-[49px] lg:my-[15px] lg:text-[56px]': isHeaderSticky,
                                            'my-[10px] text-[49px] lg:my-[18px] lg:text-[80px]': !isHeaderSticky,
                                            'bg-red-500': logoBackgroundColor === 'red',
                                            'bg-transparent': logoBackgroundColor === 'transparent'
                                        }" />
                                </nuxt-link>

                                <!-- Static links for nav modal -->
                                <v-button v-if="currentLang === 'et'"
                                    class="hidden px-3 py-sm font-sans-caption text-base
                                    capitalize leading-5 tracking-xl hover:underline xl:block"
                                    :aria-label="$t('aria.open-page', { page: $t('pages.rooms') })"
                                    @click="isRoomsOpen = true">
                                    {{ t('pages.rooms') }}
                                </v-button>

                                <!-- Products button -->
                                <v-button class="hidden px-3 py-sm font-sans-caption text-base
                                    capitalize leading-5 tracking-xl hover:underline xl:block"
                                    :aria-label="$t('aria.open-page', { page: $t('pages.products') })"
                                    @click="isProductsOpen = true">
                                    {{ t('pages.products') }}
                                </v-button>

                                <!-- Campaign button -->
                                <nuxt-link v-if="isCampaignsModalDisabled"
                                    :to="t('storyblok.campaigns')"
                                    class="hidden px-3 py-sm font-sans-caption text-base
                                    capitalize leading-5 tracking-xl hover:underline xl:block"
                                    :aria-label="$t('aria.open-page', { page: $t('storyblok.campaigns') })">
                                    {{ t('storyblok.campaigns') }}
                                </nuxt-link>

                                <v-button v-else
                                    class="hidden px-3 py-sm font-sans-caption text-base
                                    capitalize leading-5 tracking-xl hover:underline xl:block"
                                    :aria-label="$t('aria.open-page', { page: $t('storyblok.campaigns') })"
                                    @click="isDiscountsOpen = true">
                                    {{ t('storyblok.campaigns') }}
                                </v-button>

                                <!-- Rest of the nav links -->
                                <template v-if="headerContent">
                                    <nuxt-link v-for="(links) in headerContent.content?.header_links"
                                        :key="links._uid"
                                        :aria-label="$t('aria.open-page', { page: links.name })"
                                        :to="formatSbUrl(links.link.cached_url)"
                                        class="hidden px-3 py-sm font-sans-caption
                                            text-base leading-5 tracking-xl hover:underline xl:block">
                                        {{ links.name }}
                                    </nuxt-link>
                                </template>
                            </nav>

                            <!-- Right side buttons -->
                            <div class="mr-2 flex items-center md:mr-0">
                                <v-header-search :placeholder="headerContent?.content?.search_placeholder"
                                    :font-color="fontColor" />

                                <v-header-profile-button :font-color="fontColor" />

                                <v-header-wishlist :font-color="fontColor" />

                                <v-header-cart :font-color="fontColor" />

                                <v-header-button id="menu"
                                    aria-label="Menu"
                                    rounded
                                    class="ml-2"
                                    @click="isNavOpen = true">
                                    <v-icon icon="menu" />

                                    <p class="pl-2 text-sm">
                                        Menu
                                    </p>
                                </v-header-button>
                            </div>
                        </div>
                    </div>

                    <lazy-v-header-nav :is-open="isNavOpen || isRoomsOpen || isProductsOpen"
                        :section="isRoomsOpen ? 'rooms' : isProductsOpen ? 'products' : undefined"
                        @close="close" />

                    <lazy-v-discounts-modal
                        :is-open="isDiscountsOpen"
                        is-modal
                        @close="isDiscountsOpen = false" />
                </header>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useWindowScroll, watchDebounced } from '@vueuse/core';
import type { Icon } from '~/types';

defineEmits<{
    (event: 'close'): void
}>();

const isNavOpen = ref(false);
const isRoomsOpen = ref(false);
const isProductsOpen = ref(false);
const isDiscountsOpen = ref(false);

const { y } = useWindowScroll();
const { format: formatSbUrl } = useSbUrl();

const { $i18n } = useNuxtApp();
const currentLang = computed(() => $i18n.locale.value);

const stickyOnOffset = 300;
const stickyOffOffset = 1000;
const stickyTopBoundary = 200;
const isHeaderSticky = ref(false);
const previousScrollPosition = ref(y.value);
const scrollDirection = ref<'up' | 'down'>('down');

onMounted(() => {
    isHeaderSticky.value = y.value > stickyOnOffset;
});

watch(scrollDirection, () => {
    previousScrollPosition.value = y.value;
});

watch(y, (value, previousValue) => {
    scrollDirection.value = value > previousValue ? 'down' : 'up';

    if (value < previousScrollPosition.value - stickyOffOffset || value < stickyTopBoundary) {
        isHeaderSticky.value = false;
    } else if (value > previousScrollPosition.value + stickyOnOffset) {
        isHeaderSticky.value = true;
    }
});

watchDebounced(y, (value) => {
    previousScrollPosition.value = value;
}, { debounce: 500 });

const { t } = useI18n();

function close() {
    isNavOpen.value = false;
    isRoomsOpen.value = false;
    isProductsOpen.value = false;
}

const headerContent = useStoryblokStory('global/header');
const infoBar = useStoryblokStory('global/info-bar');

const infoBarBackground = computed<string | undefined>(() => (
    infoBar.value?.content?.background_color?.color ?? '#EF1C35'
));

const infoBarfontColor = computed<string | undefined>(() => (
    infoBar.value?.content?.font_color?.value && infoBar.value.content.font_color.value.length > 0
        ? infoBar.value?.content?.font_color.value
        : '#FFF'
));

const backgroundColor = computed<string | undefined>(() => (
    headerContent.value?.content?.background_color?.color ?? '#EF1C35'
));

const fontColor = computed<string | undefined>(() => (
    headerContent.value?.content?.font_color ?? '#FFF'
));

const logoBackgroundColor = computed<string | undefined>(() => (
    headerContent.value?.content?.logo_background ?? 'rgba(0, 0, 0, 0)'
));

const headerLogo = computed<Icon>(() => (
    headerContent.value?.content?.header_logo ?? 'masku-2024'
));

const isCampaignsModalDisabled = computed<boolean>(() => (
    headerContent.value?.content?.disable_campaigns_modal ?? false
));
</script>

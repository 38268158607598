<template>
    <div>
        <slot :products="products"
            :is-loading="isLoading" />
    </div>
</template>

<script lang="ts" setup>
const properties = defineProps<{
    name?: string
}>();

const emit = defineEmits<{
    (event: 'no-products'): void
}>();

const { getLandingPage } = useElevate();
const route = useRoute();
const pageType = inject<string>('pageType', 'default');

const path = pageType === '404' ? '/404' : route.path;

const recommendations = await getLandingPage(path, undefined, undefined, undefined, undefined, true);

const isLoading = computed(() => (
    properties.name && properties.name.length > 0 ? recommendations.status.value !== 'success' : false
));

const placement = computed(() => (
    properties.name && properties.name.length > 0
        ? recommendations.data.value?.recommendationLists.find((item) => item.id === properties.name)
        : undefined
));

const products = computed(() => placement.value?.productGroups.flatMap((group) => group.products) || []);

watch(isLoading, (value) => {
    if (!value && products.value.length === 0) {
        emit('no-products');
    }
});
</script>

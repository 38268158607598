<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
    <div v-if="$slots.header">
        <div class="mb-6">
            <slot name="header"
                :move="move"></slot>
        </div>

        <div ref="scrollable"
            v-bind="$attrs"
            class="relative overflow-auto scroll-smooth scrollbar-thin scrollbar-track-gray-100
                scrollbar-thumb-black scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
            :class="{
                'scrollbar-none': noScrollbar,
                'pb-sm': !noScrollbar,
            }"
            @mousedown="mouseIsDown"
            @mouseup="mouseUp"
            @mouseleave="mouseLeave"
            @mousemove="mouseMove">
            <slot />
        </div>
    </div>

    <div v-else
        ref="scrollable"
        v-bind="$attrs"
        class="relative overflow-auto scroll-smooth scrollbar-thin scrollbar-track-gray-100
            scrollbar-thumb-black scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
        :class="{
            'scrollbar-none': noScrollbar,
            'pb-sm': !noScrollbar,
        }"
        @mousedown="mouseIsDown"
        @mouseup="mouseUp"
        @mouseleave="mouseLeave"
        @mousemove="mouseMove">
        <slot />
    </div>
</template>

<script lang="ts" setup>
defineProps<{
    noScrollbar?: boolean
}>();

const scrollable = ref<HTMLDivElement>();

function move(direction: number) {
    const width = scrollable.value?.querySelector('.scrollable-item:first-child')?.clientWidth || 0;

    scrollable.value?.scroll({
        left: scrollable.value.scrollLeft + (direction > 0 ? width : -width),
        behavior: 'smooth',
    });
}

const startX = ref<number>(0);
const scrollLeft = ref<number>(0);
const isDown = ref<boolean>(false);
const isMoving = ref<boolean>(false);

function mouseIsDown(event: MouseEvent) {
    if (!scrollable.value) {
        return;
    }

    event.preventDefault();

    isDown.value = true;
    startX.value = event.pageX - scrollable.value.offsetLeft;
    scrollLeft.value = scrollable.value?.scrollLeft;
}

function mouseUp() {
    isDown.value = false;
    isMoving.value = false;
}

function mouseLeave() {
    isDown.value = false;
    isMoving.value = false;
}

function mouseMove(event: MouseEvent) {
    if (!scrollable.value || !isDown.value) {
        return;
    }

    event.preventDefault();

    isMoving.value = true;

    // move horizontally
    const x = event.pageX - scrollable.value.offsetLeft;
    const walkX = x - startX.value;
    scrollable.value.scrollLeft = scrollLeft.value - walkX;
}
</script>

<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>

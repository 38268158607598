<template>
    <div>
        <v-subtitle class="mb-md font-bold">
            {{ t('cart-modal-totalprice') }}
        </v-subtitle>

        <div class="flex flex-col rounded-xs border border-solid border-gray-100 bg-white">
            <div class="flex flex-row justify-between border border-x-0 border-t-0 border-solid border-gray-100 p-md">
                <p>
                    {{ t('delivery-time') }}
                </p>

                <p class="text-black">
                    {{ formatDeliveryEstimateText(longestDeliveryProduct.product.inventory, 'cart') }}
                </p>
            </div>

            <div class="flex flex-row justify-between border border-x-0 border-t-0 border-solid border-gray-100 p-md">
                <p>{{ t('cart-modal-subtotal') }}</p>

                <p> {{ formatCurrency(productsTotal, { maximumFractionDigits: 2 }) }}</p>
            </div>

            <!-- <div class="flex flex-row justify-between border border-solid
                border-x-0 border-t-0 border-gray-100 p-md">
                <p>Toimituskulut</p>
                <p> {{ formatCurrency(5.90) }}</p>
            </div> -->

            <div v-if="isFreeShipping"
                class="flex flex-row justify-between border border-x-0
                border-t-0 border-solid border-gray-100 p-md">
                <p>{{ t('code-discount') }}</p>

                <p
                    class="text-red-600">
                    {{ t('free-shipping') }}
                </p>
            </div>

            <div v-if="discountsTotal > 0"
                class="flex flex-row justify-between border border-x-0 border-t-0 border-solid border-gray-100 p-md">
                <p>{{ t('saved') }}</p>

                <p class="text-red-600">{{ formatCurrency(discountsTotal, { maximumFractionDigits: 2 }) }} </p>
            </div>

            <p class="flex justify-between
                border border-x-0 border-t-0 border-gray-100 p-md">
                <span>
                    {{ t('cart-price-taxless') }}
                </span>

                <span class="text-gray-500">
                    {{ formatCurrency(
                        calculateZeroTax(Number.parseFloat(cart.price.amount), taxRate),
                        { maximumFractionDigits: 2 },
                    ) }}
                </span>
            </p>

            <div class="flex flex-row justify-between p-md">
                <div>
                    <p class="font-bold">{{ t('cart-modal-finalprice') }}</p>

                    <p class="text-gray-500">{{ t('include-tax') }} {{ convert(taxRate) }}%</p>
                </div>

                <p class="font-bold">{{ formatCurrency(cart.price.amount, { maximumFractionDigits: 2 }) }}</p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type {
    ViewCartQuery,
} from '~~/graphql';

type Product = Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>['products'][0];

const properties = defineProps<{
    cart: Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>
}>();

const { t } = useI18n();
const { format: formatCurrency } = useCurrency();
const { format: formatDeliveryEstimateText } = useDeliveryEstimateText();

const { convert } = useTaxConversion();
const { calculateZeroTax } = useTax();

const taxRate = computed<number>(() => properties.cart.products[0].product.pricing.taxClass.rate ?? 0);

const productsTotal = computed(() => properties.cart.products.reduce((accumulator, item) => (
    accumulator + (Number.parseFloat(item.product.pricing.originalPrice?.amount ?? '0') * item.quantity)
), 0) || 0);

const discountsTotal = computed(() => (
    properties.cart.products.reduce((accumulator, item) => {
        const originalTotal = Number(item.product.pricing.originalPrice?.amount ?? '0') * item.quantity;
        const totalDiscount = item.discounts.reduce((total, discount) => total + Number(discount.amount.amount), 0);
        const currentPrice = Number(item.price.amount);

        return accumulator + totalDiscount + (originalTotal - totalDiscount - currentPrice);
    }, 0)
));

const isFreeShipping = computed(() => {
    if (properties.cart.promotionCode !== null) {
        return properties.cart.promotionCode.promotion.__typename === 'CartPromotionFreeShipping';
    }

    return false;
});

const longestDeliveryProduct = computed<Product>(() => {
    let maxDeliveryProduct: Product | undefined;

    for (const product of properties.cart.products) {
        const productDeliveryEstimate = product.product.inventory.deliveryEstimateDays ?? undefined;

        if (
            !maxDeliveryProduct
            || (
                productDeliveryEstimate !== undefined
                && productDeliveryEstimate > maxDeliveryProduct.product.inventory.deliveryEstimateDays!
            )
        ) {
            maxDeliveryProduct = product;
        }
    }

    return maxDeliveryProduct || properties.cart.products[0];
});
</script>

<i18n lang="json">
{
    "et": {
        "order-info": "Tellimuse andmed",
        "cart-product-finalprice": "Kokku",
        "cart-products-count": "{count} toode, kokku | {count} toodet, kokku",
        "saved": "Säästetud",
        "cart-price-taxless": "Summa km-ta",
        "cart-modal-subtotal": "Vahesumma",
        "include-tax": "Sis. KM",
        "go-to-checkout": "Suundu maksma",
        "code-discount": "Kampaaniakoodiga",
        "cart-modal-code": "Sisesta sooduskood",
        "cart-modal-totalprice": "Kogusumma",
        "cart-modal-finalprice": "Kokku",
        "free-shipping": "Tasuta saatmine",
        "delivery-time": "Tarneaeg"
    },
    "fi": {
        "order-info": "Tilauksen tiedot",
        "cart-product-finalprice": "yhteensä",
        "cart-products-count": "{count} tuote, yhteensä | {count} tuotetta, yhteensä",
        "saved": "Säästit",
        "cart-price-taxless": "Veroton hinta yhteensä",
        "cart-modal-subtotal": "Välisumma",
        "include-tax": "Sis. Alv.",
        "go-to-checkout": "Siirry kassalle",
        "code-discount": "Kampanjakoodi aktivoitu",
        "cart-modal-code": "Syötä kampanjakoodi",
        "cart-modal-totalprice": "Kokonaissumma",
        "cart-modal-finalprice": "Loppusumma",
        "free-shipping": "Ilmainen toimitus",
        "delivery-time": "Toimitusaika"
    }
}
</i18n>

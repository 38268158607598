<template>
    <div class="space-y-sm px-10 py-sm odd:bg-gray-50">
        <p class="mb-xs text-base font-bold">{{ outlet.name }}</p>

        <div class="flex items-start justify-between space-x-3">
            <div class="space-y-2">
                <dl v-if="schedule.length > 0"
                    class="row-auto mr-auto grid grid-cols-2 gap-x-lg text-sm">
                    <template v-for="(row, index) in schedule"
                        :key="index">
                        <dt>
                            <template v-if="row.days.length > 1">
                                {{ row.days[0] }}-{{ row.days.at(-1) }}
                            </template>

                            <template v-else-if="row.days[0] === ''">
                                {{ t('no-hours') }}
                            </template>

                            <template v-else>
                                {{ row.days[0] }}
                            </template>
                        </dt>

                        <dd>
                            {{ row.hours }}
                        </dd>
                    </template>
                </dl>

                <p v-else
                    class="text-sm">
                    {{ t('no-hours') }}
                </p>
            </div>

            <button type="button"
                class="rounded-lg border border-gray-800 px-4 py-2 outline-none transition-all duration-150 ease-in-out"
                :class="{
                    // eslint-disable-next-line max-len
                    'hover:bg-gray-100 hover:text-gray-700 focus:bg-gray-300 focus:text-gray-700 active:bg-gray-300 active:text-gray-700': !isSelected,
                    'bg-[rgba(40,162,43,0.2)]': isSelected,
                }"
                :disabled="isSelected"
                @click="emit('update:modelValue', outlet)">
                <span v-if="isSelected">
                    <v-icon icon="check"></v-icon>
                    {{ t('picker-chosen-shop') }}
                </span>

                <span v-else>
                    {{ t('picker-choose-shop') }}
                </span>
            </button>
        </div>

        <div v-if="haveExceptionsText">
            <p class="font-bold">
                {{ t('exceptions') }}
            </p>

            <v-storyblok-rich-text
                class="text-sm"
                no-prose
                :document="outlet.content.hours" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ISbStoryData } from '@storyblok/js';
import type { IHoursTable } from '~/types';

const properties = defineProps<{
    modelValue?: ISbStoryData
    outlet: ISbStoryData
}>();

const emit = defineEmits<{
    (event: 'update:modelValue', value: ISbStoryData): void
}>();

const { t } = useI18n();

const isSelected = computed(() => properties.modelValue?.uuid === properties.outlet.uuid);
const hoursBody = computed<IHoursTable | undefined>(() => properties.outlet.content.hours_table);

const exceptionsText = computed(() => properties.outlet.content.hours);
const haveExceptionsText = computed(() => isFilledRichText(exceptionsText.value));

const schedule = computed(() => {
    const groups: {
        days: string[]
        hours: string
    }[] = [];

    for (const day of (hoursBody.value?.tbody || [])) {
        const lastGroup = groups.at(-1);

        if (lastGroup && lastGroup.hours === day.body[1].value) {
            lastGroup.days.push(day.body[0].value.slice(0, 2));
        } else {
            groups.push({
                days: [day.body[0].value.slice(0, 2)],
                hours: day.body[1].value,
            });
        }
    }

    return groups;
});
</script>

<i18n lang="json">
{
    "fi": {
        "date-locale": "fi-FI",
        "picker-choose-shop": "Valitse",
        "picker-chosen-shop": "Valittu",
        "exceptions": "Poikkeukset",
        "no-hours": "Ei aukioloaikoja"
    },
    "et": {
        "date-locale": "et-ET",
        "picker-choose-shop": "Valige",
        "picker-chosen-shop": "Valitud",
        "exceptions": "Erandid",
        "no-hours": "Ei lahtiolekuaegu"
    }
}
</i18n>

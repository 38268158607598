import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  "aria-hidden": "true",
  viewBox: "-1 0 82 80"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      d: "m73.79 36.09-2.13-8.55.24-.05a1 1 0 0 0 .78-1v-1.31a4.94 4.94 0 0 0-4.94-4.94h-9.52v-2.93A2.32 2.32 0 0 0 55.88 15H8.57a2.31 2.31 0 0 0-2.3 2.31v22.36a1 1 0 1 0 2 0V17.31a.33.33 0 0 1 .32-.32h47.29a.32.32 0 0 1 .32.32v22.36a1 1 0 0 0 2 0v-1.64H73a4.25 4.25 0 0 1 4.06 3l.1.31H73a1 1 0 0 0-1 1v2.63a3.62 3.62 0 0 0 3.62 3.62h1.64v5.9h-3.62l-.06-.16a7.56 7.56 0 0 0-14.3 0l-.06.16h-1v-9.56a1 1 0 0 0-2 0v9.52h-26l-.06-.16a7.56 7.56 0 0 0-14.3 0l-.06.16H8.57a.32.32 0 0 1-.32-.32v-2.95h5.63a1 1 0 0 0 0-2H2a1 1 0 1 0 0 2h4.27v3a2.31 2.31 0 0 0 2.3 2.31h6.91v.24l-.24.08h.24a7.57 7.57 0 0 0 15.14 0v-.24l.24-.08h28.02v.24l-.23.08h.23a7.57 7.57 0 0 0 15.14 0v-.24l.24-.08h4a1 1 0 0 0 1-1V42.3a6.29 6.29 0 0 0-5.31-6.19zM58.22 22.25h9.52a3 3 0 0 1 3 2.95v.32H58.22zm0 13.79v-8.53H69.6l2.13 8.53zm-35.18 26.3a5.58 5.58 0 1 1 0-11.16 5.58 5.58 0 0 1 0 11.16m43.39 0a5.58 5.58 0 1 1 0-11.16 5.58 5.58 0 0 1 0 11.16m10.84-15.78h-1.64A1.63 1.63 0 0 1 74 44.93v-1.64h3.27zm-54.23 7.89a2.31 2.31 0 1 0 2.31 2.31 2.32 2.32 0 0 0-2.31-2.31m43.39 0a2.31 2.31 0 1 0 2.31 2.31 2.32 2.32 0 0 0-2.31-2.31m-33.87-4.27a1 1 0 0 0 1 1h18.41a1 1 0 0 0 0-2H33.56a1 1 0 0 0-1 1M4.63 45.92h15.78a1 1 0 0 0 0-2H4.63a1 1 0 1 0 0 2m37.43-18.74L30.93 38.26l-5.87-5.87a1 1 0 0 0-.71-.29 1 1 0 0 0-.7 1.7l6.58 6.57a1 1 0 0 0 1.4 0l11.83-11.84a1 1 0 0 0-1.4-1.4z"
    }, null, -1)
  ])))
}
export default { render: render }
<template>
    <div v-if="blok">
        <nuxt-link :id="blok.uuid"
            :to="'/' + blok.full_slug"
            :aria-label="blok.content?.title"
            class="flex w-full shrink-0 flex-col space-y-sm md:w-auto lg:w-auto">
            <nuxt-picture v-if="blok.content?.image?.filename"
                :src="blok.content.image.filename"
                :alt="blok.content.title"
                class="aspect-square w-full"
                loading="lazy"
                provider="storyblok"
                sizes="xs:60vw md:240px lg:325px xl:411px 2xl:496px"
                :img-attrs="{
                    class: 'rounded-2xs object-cover w-full h-full'
                }" />

            <div class="space-y-xs">
                <span class="font-prenton-cond text-2xl break-words tracking-2xl xl:text-3xl">
                    {{ addHyphens(blok.content?.title) }}
                </span>

                <div v-if="blok.content?.ingress"
                    class="not-prose line-clamp-1 overflow-hidden text-ellipsis
                        break-words text-sm leading-6 tracking-xl text-gray-750">
                    <sb-rich-text :document="blok.content?.ingress"></sb-rich-text>
                </div>
            </div>
        </nuxt-link>
    </div>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/naming-convention */
import type { ISbStoryData } from 'storyblok-js-client';

defineProps<{
    article?: string
    blok: ISbStoryData
}>();

const { hyphenate: addHyphens } = useHyphenation();
</script>

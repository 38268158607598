import { type MaybeRef } from '@vueuse/core';

export function useStoryblokGtmStory({
    page = 1,
    perPage = 20,
    sortBy = '',
    searchTerm,
}: {
    page?: MaybeRef<number>
    perPage?: MaybeRef<number>
    sortBy?: MaybeRef<string>
    isEnabled?: MaybeRef<Record<string, unknown>>
    searchTerm?: MaybeRef<string>
    filters?: MaybeRef<Record<string, unknown>>
}) {
    const fetchKey = `popups:perPage:${unref(perPage)}`
    + `-searchTerm:${unref(searchTerm)}-sort_by:${unref(sortBy)}`;

    return useStoryblokStoriesData(fetchKey, {
        /* eslint-disable @typescript-eslint/naming-convention */
        starts_with: 'global/gtm-popups',
        page,
        per_page: perPage,
        is_startpage: false,
        sort_by: sortBy,
        search_term: searchTerm,
        /* eslint-enable @typescript-eslint/naming-convention */
    });
}

<template>
    <div class="flex flex-nowrap items-center rounded border bg-white transition-colors duration-75"
        :class="{
            'h-10': type !== 'textarea' && size === 'sm',
            'h-14': type !== 'textarea' && size === 'md',
            'border-red-500 focus-within:border-red-600 hover:border-red-600': error,
            'border-gray-100 focus-within:border-gray-200 hover:border-gray-200': !error,
        }">
        <v-icon v-if="type === 'search'"
            icon="search"
            class="mx-xs text-2xl md:mx-sm" />

        <div class="flex h-full flex-1">
            <textarea v-if="type === 'textarea'"
                v-model="value"
                :aria-label="label"
                :name="name"
                class="block size-full bg-transparent px-3 py-1 outline-none"
                :class="{
                    'text-left': align === 'left',
                    'text-right': align === 'right',
                    'text-center': align === 'center',
                }"
                :rows="rows"
                :placeholder="placeholder"
                :readonly="readonly" />

            <input v-else
                v-model="value"
                :aria-label="label"
                :name="name"
                class="block size-full bg-transparent px-3 py-1 outline-none
                focus-visible:rounded-2xs focus-visible:outline-2 focus-visible:outline-white
                focus-visible:ring-4 focus-visible:ring-blue-600"
                :class="{
                    'text-left': align === 'left',
                    'text-right': align === 'right',
                    'text-center': align === 'center',
                }"
                :placeholder="typeof placeholder === 'number' ? `${placeholder}` : placeholder"
                :type="type"
                :readonly="readonly"
                :autocomplete="autocomplete"
                :pattern="pattern"
                :min="min"
                :max="max"
                :step="step">

            <label :for="name"
                class="font-sans text-xs tracking-3xl text-gray-500">
                <p class="mb-sm mt-2xs">{{ label }}</p>
            </label>
        </div>
    </div>
</template>

<script setup lang="ts">
const properties = withDefaults(defineProps<{
    modelValue?: string | number
    type?: 'text' | 'textarea' | 'password' | 'date' | 'number' | 'search' | 'tel' | 'email'
    placeholder?: string
    align?: 'left' | 'right' | 'center'
    size?: 'sm' | 'md'
    required?: boolean
    readonly?: boolean
    error?: boolean
    name?: string
    label?: string
    pattern?: string
    autocomplete?: string
    min?: number
    max?: number
    step?: number
    rows?: number
}>(), {
    modelValue: undefined,
    type: 'text',
    placeholder: undefined,
    align: 'left',
    size: 'sm',
    name: undefined,
    label: undefined,
    pattern: undefined,
    autocomplete: undefined,
    min: undefined,
    max: undefined,
    step: undefined,
    rows: 4,
});

const emit = defineEmits<{
    (event: 'update:modelValue', value: string | number): void
}>();

const value = computed({
    get() {
        return properties.modelValue || '';
    },
    set(newValue: string | number) {
        emit('update:modelValue', newValue);
    },
});
</script>

<template>
    <component :is="tag"
        :type="to ? undefined : 'button'"
        :to="to"
        :aria-label="ariaLabel ?? undefined"
        class="group relative flex flex-row justify-between text-3xl outline-none"
        :class="{
            // eslint-disable-next-line max-len
            'rounded-full bg-brand-gray px-3 py-2 text-black hover:bg-[#161310] hover:text-brand-gray focus:opacity-90 focus-visible:bg-[#161310] focus-visible:text-brand-gray focus-visible:outline-2 focus-visible:outline-white focus-visible:ring-4 focus-visible:ring-blue-600 active:opacity-80': rounded,
            'p-2': !rounded,
        }">
        <div class="flex"
            :class="{
                'group-hover:opacity-90 group-focus:opacity-90 group-active:opacity-80': !rounded,
            }">
            <slot></slot>
        </div>

        <span v-if="badge"
            class="absolute bottom-1 right-0 flex min-w-sm items-center justify-center rounded-full
                bg-black px-1 text-xs text-white">
            {{ badge }}
        </span>
    </component>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';
import { NuxtLink } from '#components';

const properties = defineProps<{
    as?: string
    to?: RouteLocationRaw
    badge?: number | string
    rounded?: boolean
    ariaLabel?: string
}>();

const tag = computed(() => {
    if (properties.as) {
        return properties.as;
    }
    if (properties.to) {
        return NuxtLink;
    }

    return 'button';
});
</script>

<template>
    <!-- Change isPopupVisible condition to consent checking. -->
    <transition v-show="consentIsSet && content !== undefined && isPopupVisible && !isPopupClosed"
        mode="in-out"
        enter-active-class="transition-opacity duration-900 ease-in"
        enter-from-class="opacity-0 translate-x-1"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-900 ease-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0 translate-x-1">
        <div v-if="content !== undefined && isPopupVisible"
            class="fixed top-0 z-50 h-screen w-screen bg-black/50">
            <div class="fixed left-1/2 top-1/2 z-50
                h-3/4 w-full -translate-x-1/2 -translate-y-1/2
                bg-[color:var(--background-color)] text-[color:var(--text-color)]
                [filter:drop-shadow(-3px_7px_7px_rgb(0_0_0/80%))]
                md:h-1/2 md:w-2/3 md:rounded-xs lg:h-2/3 lg:w-1/2
                xl:w-1/2 2xl:w-1/3"
                :style="{
                    '--background-color': backgroundColor,
                    '--text-color': fontColor
                }">
                <button type="button"
                    class="absolute -top-5 right-4 z-20 size-10 rounded-full
                    bg-red-500 text-white md:-right-2 md:-top-2 md:size-8"
                    @click="close">
                    <v-icon icon="close" />
                </button>

                <component :is="content.content.component"
                    :id="content.content.gtm_id"
                    :name="content.name"
                    :blok="content.content"
                    @close="close" />
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
const isPopupClosed = ref(false);
const { categories } = useCookieFirst();
const isPopupVisible = ref(false);
const consentIsSet = ref(categories.value?.advertising === true);

const cookieGtmId = ref('');

const gtmCookies = useCookie<Array<{ id: string }>>('cc_popup_items', {
    maxAge: 60 * 60 * 24 * 30, // 30 days
});

if (!Array.isArray(gtmCookies.value)) {
    gtmCookies.value = [];
}

const content = ref();

/**
 * Handles a custom event to manage the visibility and content of a popup.
 *
 * @param {CustomEvent} event - The custom event containing the popup ID.
 *
 * The function performs the following steps:
 * 1. Extracts the popup ID from the event details and assigns it to `cookieGtmId`.
 * 2. Calls `useGtmPopup` with the extracted `gtmId` to get the popup response.
 * 3. Checks if the popup ID exists in `gtmCookies`. If it does, sets `isPopupVisible` to false.
 * 4. If the popup ID does not exist in `gtmCookies` and the response contains stories,
 *   sets the first story to `content` and makes the popup visible by setting `isPopupVisible` to true.
 */
const handleCustomEvent = (event: CustomEvent) => {
    const gtmId = event.detail.popupId as string;
    cookieGtmId.value = gtmId;

    if (!gtmCookies.value.some((cookie) => cookie.id === cookieGtmId.value)) {
        const { data: contents } = useStoryblokGtmStory({
            /* eslint-disable @typescript-eslint/naming-convention */
            perPage: 20,
            sortBy: 'content.name:desc',
            searchTerm: gtmId,
            /* eslint-enable @typescript-eslint/naming-convention */
        });
        if (contents) {
            content.value = contents.value?.stories[0];
            isPopupVisible.value = true;
        }
    }
};

/**
 * Closes the popup by setting `isPopupVisible` to false.
 * Adds a new cookie with the current `cookieGtmId` to the `gtmCookies` array.
 * If `gtmCookies` is not defined, initializes it with the new cookie.
 */
function close() {
    isPopupVisible.value = false;

    const newCookie = { id: cookieGtmId.value };
    if (gtmCookies.value) {
        gtmCookies.value.push(newCookie);
    } else {
        gtmCookies.value = [newCookie];
    }
}

/** Color management
 *  Colors are taken from the enabled component's information.
 */
const backgroundColor = computed(() => content.value?.content?.background_color?.color || '#FFF');
const fontColor = computed(() => content.value?.content?.font_color?.color || '#000');

/** Mounting
 * When the component is mounted, we are listening to the custom event
 * that is triggered from GTM.
 * This is used to show the popup in handleTrigger function when the GTM trigger is fired.
 * The event listener is removed when the component is unmounted.
 */
onMounted(() => {
    if (consentIsSet.value) {
        setTimeout(() => {
            window.addEventListener('popupTriggerEvent', handleCustomEvent as EventListener);
        }, 300, { immediate: true });
    }
});

onBeforeUnmount(() => {
    window.removeEventListener('popupTriggerEvent', handleCustomEvent as EventListener);
});
</script>

<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
    <nuxt-link :id="blok._uid"
        :to="url"
        class="aspect-19/6 relative flex size-full h-full flex-col justify-center overflow-hidden break-words
            rounded text-center text-[color:var(--text-color)]"
        :style="{
            '--text-color': fontColor
        }"
        :title="blok.background?.alt ?? ''"
        :aria-label="blok.title">
        <div v-if="!hideCampaign"
            class="relative z-10 flex size-full flex-col justify-between p-sm"
            :class="{
                'custom-shadow': fontColor !== '#000000',
            }">
            <nuxt-picture v-if="hasBrandLogo"
                :src="blok.logo?.filename"
                :alt="blok.logo?.alt ?? blok.campaign_title"
                provider="storyblok"
                loading="lazy"
                sizes="xs:240px sm:240px md:320px lg:320px xl:320px 2xl:320px"
                :img-attrs="{
                    class: 'object-cover h-full '
                }"
                class="mt-sm flex h-[75px] w-auto justify-center rounded object-cover" />

            <div class="w-full px-md">
                <div class="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
                    <p class="break-normal px-md font-prenton-cond text-2xl
                        font-bold uppercase leading-6
                        tracking-[.10rem] md:text-5xl md:leading-[3rem]">
                        {{ blok.campaign_title }}
                    </p>

                    <p v-if="blok.campaign_percent !== ''"
                        class="font-prenton-cond text-12xl font-bold
                        uppercase text-[color:var(--text-color)] md:text-[8rem]"
                        :style="{
                            '--text-color': percentagefontColor
                        }">
                        {{ blok.campaign_percent }}
                    </p>

                    <p v-if="blok.campaign_additional_content !== ''"
                        class="truncate font-sans text-base font-bold md:text-lg">
                        {{ blok.campaign_additional_content }}
                    </p>
                </div>
            </div>

            <p v-if="blok.campaign_cta_text !== ''"
                class="mt-8 shrink rounded-xs bg-[color:var(--background-color)] p-2
                font-prenton-cond text-xl uppercase leading-5 tracking-5xl
                text-[color:var(--text-color)] underline-offset-4 hover:underline"
                :class="{ 'no-underline': buttonBackgroundColor }"
                :style="{
                    '--background-color': buttonBackgroundColor,
                    '--text-color': buttonFontColor
                }">
                {{ blok.campaign_cta_text }}
            </p>
        </div>

        <nuxt-picture :src="blok.background?.filename"
            :alt="blok.background?.alt ?? blok.campaign_title"
            provider="storyblok"
            loading="lazy"
            width="320"
            height="600"
            sizes="xs:240px sm:240px md:320px lg:320px xl:320px 2xl:320px"
            :img-attrs="{
                class: 'object-cover h-full w-full'
            }"
            class="absolute inset-0" />
    </nuxt-link>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-product-highlight' | 'sb-product-highlight-square' }>
}>();

const { format: formatSbUrl } = useSbUrl();

const url = computed(() => formatSbUrl(properties.blok.link?.cached_url));

const hideCampaignValue = computed(() => (properties.blok.hide_campaign ?? false));

const hasCampaign = computed<boolean>(() => {
    if ((properties.blok.campaign_title === undefined
        || properties.blok.campaign_percent === undefined
        || properties.blok.campaign_additional_content === undefined
        || properties.blok.campaign_cta_text === undefined)) {
        return false;
    }

    return true;
});

const hideCampaign = computed<boolean>(() => {
    if (hasCampaign.value && !hideCampaignValue.value) {
        return false;
    }

    if (hasCampaign.value && hideCampaignValue.value) {
        return true;
    }

    if (!hasCampaign.value && !hideCampaignValue.value) {
        return true;
    }
    return false;
});

const hasBrandLogo = computed(() => (
    (properties.blok.logo?.filename) ?? false
));

const fontColor = computed<string | undefined>(() => (properties.blok.text_color === undefined
    ? '#FFF'
    : properties.blok.text_color.color));

const percentagefontColor = computed<string | undefined>(() => (properties.blok.campaign_percentage_color === undefined
    ? '#EF1C35'
    : properties.blok.campaign_percentage_color.color));

const buttonBackgroundColor = computed<string | undefined>(() => (
    properties.blok.button_background_color?.color ?? '#FFF'
));

const buttonFontColor = computed<string | undefined>(() => (
    properties.blok.button_text_color?.color ?? '#000'
));
</script>

<style scoped>
.custom-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}
</style>

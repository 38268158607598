<template>
    <component :is="tag"
        :to="to"
        class="flex items-center space-x-3 hover:underline">
        <v-icon :icon="icon"
            class="text-3xl" />

        <span>
            <slot></slot>
        </span>
    </component>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';
import { NuxtLink } from '#components';
import type { Icon } from '~~/types';

const properties = defineProps<{
    icon: Icon
    to?: RouteLocationRaw
}>();

const tag = computed(() => {
    if (properties.to) {
        return NuxtLink;
    }

    return 'button';
});
</script>

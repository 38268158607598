<template>
    <p v-if="isVisible"
        class="flex flex-nowrap space-x-xs overflow-auto whitespace-nowrap text-sm
        tracking-xl text-[#5A5454] scrollbar-none">
        <template v-for="crumb, index in crumbs"
            :key="crumb.url">
            <span v-if="index > 0"> / </span>

            <nuxt-link :to="crumb.url"
                :title="crumb.title"
                class="hover:underline">
                {{ crumb.title }}
            </nuxt-link>
        </template>
    </p>

    <v-placeholder v-else
        class="block h-6 rounded bg-gray-50 before:via-gray-100" />
</template>

<script setup lang="ts">
import { whenever } from '@vueuse/core';
import type { StoryblokLink } from '~/composables/use-storyblok-links';
import type { TreeItem } from '~/server/api/navigation.get';
import type { SimpleProductDataFragment } from '~~/graphql';

const properties = withDefaults(defineProps<{
    type?: 'page' | 'product' | 'profile'
    product?: SimpleProductDataFragment
}>(), {
    type: 'page',
    product: undefined,
});

const config = useRuntimeConfig();

const route = useRoute();
const { csrf } = useCsrf();

const links = properties.type === 'page' ? useStoryblokLinks().data : ref<StoryblokLink[]>([]);
const navigation = ref<TreeItem[]>([]);

if (properties.type === 'product') {
    const { data } = await useFetch('/api/navigation', {
        headers: {
            ...csrf ? {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'csrf-token': csrf,
            } : {},
        },
    });

    whenever(data, (value) => {
        navigation.value = value;
    }, { immediate: true });
}

const isVisible = computed(() => (
    (properties.type === 'product' && navigation.value.length > 0)
    || (properties.type === 'page' && links.value && links.value.length > 0)
    || properties.type === 'profile'
));

interface Crumb {
    url: string
    title: string
}

const { t } = useI18n({
    useScope: 'global',
});

type TreeItemWithParent = TreeItem & {
    parent?: TreeItemWithParent
};

function flattenNavigation(tree: TreeItem[], parent?: TreeItemWithParent): TreeItemWithParent[] {
    return tree.reduce((accumulator, item) => {
        const flat = [
            ...accumulator,
            { ...item, parent },
        ];

        if (item.children) {
            return [
                ...flat,
                ...flattenNavigation(item.children, item),
            ];
        }

        return flat;
    }, [] as TreeItemWithParent[]);
}

const flatNavigation = computed(() => flattenNavigation(navigation.value));
const { format: formatSbUrl } = useSbUrl();

const crumbs = computed<Crumb[]>(() => {
    if (properties.type === 'product') {
        const mainCategory = flatNavigation.value.find((item) => (
            item.type === 'category' && item.categoryId === properties.product?.mainCategory?.id
        ));

        const categoryPath: TreeItem[] = [];

        if (mainCategory) {
            categoryPath.unshift(mainCategory);

            let currentCategory = mainCategory.parent;

            while (currentCategory) {
                categoryPath.unshift(currentCategory);

                if (!currentCategory.parent) {
                    break;
                }

                // eslint-disable-next-line @typescript-eslint/no-loop-func
                currentCategory = flatNavigation.value.find((item) => item.id === currentCategory?.parent?.id);
            }
        }

        if (categoryPath.length === 0) {
            return [
                {
                    url: formatSbUrl(t('storyblok.products')),
                    title: t('pages.products'),
                },
                {
                    url: route.path,
                    title: properties.product?.name || '',
                },
            ];
        }

        return [
            {
                url: formatSbUrl(t('storyblok.rooms')),
                title: t('pages.rooms'),
            },
            ...categoryPath.map((item) => ({
                url: item.url || '',
                title: item.name,
            })),
        ];
    }

    if (properties.type === 'profile') {
        return [
            {
                url: formatSbUrl(t('storyblok.home')),
                title: t('pages.home'),
            },
            {
                url: route.path,
                title: t('pages.profile'),
            },
        ];
    }

    const slugs = route.path
        .replace(/^\//, '')
        .replace(/\/$/, '')
        .split('/');

    const pages = slugs.map((slug, index) => slugs.slice(0, index + 1).join('/'));

    return [
        {
            url: formatSbUrl(t('storyblok.home')),
            title: t('pages.home'),
        },
        ...(links.value ?? [])
            .filter((link) => pages.includes(link.slug))
            .map((link) => ({
                url: link.real_path,
                title: link.name,
            }))
            .sort((a, b) => a.url.length - b.url.length),
    ];
});

/* eslint-disable @typescript-eslint/naming-convention */
useJsonld({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: crumbs.value.map((crumb, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: crumb.title,
        item: `${config.public.appUrl}${crumb.url}`,
    })),
});
/* eslint-enable @typescript-eslint/naming-convention */
</script>

<template>
    <div class="relative">
        <button v-if="type === 'SimpleProduct'"
            type="button"
            class="group absolute right-xs top-xs z-20 outline-none"
            :title="tg('aria.add-to-wishlist')"
            @click.stop="addToWishlist">
            <v-icon
                icon="heart"
                class="size-[18px] group-hover:fill-red-500
                    group-focus:fill-red-500 group-active:fill-red-600
                    md:size-md" />
        </button>

        <nuxt-link :to="url"
            class="group"
            @click="onClick">
            <div class="relative flex aspect-square items-center justify-center rounded bg-white"
                :class="{
                    'p-sm': imageType === 'cutout',
                    'overflow-hidden': imageType === 'model',
                }">
                <v-sale-tag v-if="promotion"
                    class="absolute left-xs top-xs"
                    :promotion="promotion"
                    theme="black"
                    :price="price"
                    :original-price="originalPrice"
                    :club-price="clubPrice"
                    is-search-modal />

                <div v-if="imageType === 'cutout'"
                    class="absolute inset-0 rounded bg-gray-500/5 group-hover:bg-gray-500/10"></div>

                <nuxt-picture v-if="image"
                    :src="image.url"
                    :alt="product.title || ''"
                    loading="lazy"
                    :width="image.width"
                    :img-attrs="{
                        class: `w-full h-full ${imageType === 'cutout' ? 'object-contain' : 'object-cover'}`,
                    }"
                    class="aspect-square"
                    :sizes="imageType === 'cutout'
                        ? 'xs:50vw sm:33vw md:25vw lg:20vw xl:144px 2xl:144px'
                        : 'xs:50vw sm:33vw md:25vw lg:20vw xl:176px 2xl:176px'
                    " />

                <!-- Tuotekortin tagit -->
                <div class="absolute bottom-xs left-xs flex flex-row
                        items-end gap-x-2 gap-y-1
                        overflow-auto scrollbar-none md:gap-y-2">
                    <template v-for="badge in textProductBadges"
                        :key="badge.id">
                        <v-product-card-badge v-if="badge.listingTitle && badge.listingTitle.length > 0"
                            :badge="badge"
                            compact>
                            {{ badge.listingTitle }}
                        </v-product-card-badge>
                    </template>
                </div>
            </div>

            <div class="p-2">
                <div class="flex flex-row items-center justify-between group-hover:underline">
                    <v-subtitle
                        class="truncate font-bold leading-4 tracking-xl text-black md:text-base md:leading-5"
                        size="sm"
                    >
                        {{ title }}
                    </v-subtitle>
                </div>

                <p v-if="specification"
                    class="truncate font-sans text-sm leading-6 text-[#565050] md:text-base">
                    {{ specification }}
                </p>

                <div v-if="originalPrice"
                    class="flex flex-row space-x-2">
                    <p class="font-prenton-cond text-lg leading-5 tracking-xl text-red-500
                        md:text-3xl md:leading-9">
                        <span v-if="type === 'ConfigurableProduct'"
                            class="text-base">
                            {{ t('product-part-payment-prefix') }}&nbsp;
                        </span>

                        <template v-if="clubPrice">
                            {{ formatCurrency(clubPrice) }}
                        </template>

                        <template v-else>
                            {{ formatCurrency(price) }}
                        </template>
                    </p>
                </div>

                <div v-if="deliveryEstimateDays > 0">
                    <p class="font-sans text-sm leading-5 text-[#146716]">
                        {{ formatDeliveryEstimateText(deliveryEstimateDays) }}
                    </p>
                </div>
            </div>
        </nuxt-link>
    </div>
</template>

<script setup lang="ts">
import type { Product } from '@apptus/esales-api';
import { capitalCase } from 'case-anything';
import { useGtm } from '@gtm-support/vue-gtm';
import { useToast } from '~~/store/toast';
import type { PromotionData, ElevateProductBadge } from '~~/types';

const properties = defineProps<{
    product: Product
}>();
const wishlist = useCart(true);
const toast = useToast();
const { $eventbus } = useNuxtApp();
const gtm = useGtm();
const { t } = useI18n();
const { t: tg } = useI18n({ useScope: 'global' });
const { format: formatCurrency } = useCurrency();
const { formatDays: formatDeliveryEstimateText } = useDeliveryEstimateText();

const { getStringValues } = useElevateProduct(properties.product);

const image = computed(() => properties.product.imageInfo.images[0].sources[0]);
const imageType = computed(() => (
    properties.product.imageInfo.images[0]?.custom?.type === 'model' ? 'model' : 'cutout'
));
const title = computed(() => (
    capitalCase(getStringValues('title')[0] || properties.product.title.split(' ')[0])
));
const specification = computed(() => (
    (getStringValues('specification')[0] || properties.product.title.split(' ').slice(1).join(' ')).toLocaleLowerCase()
));
const price = computed(() => properties.product.sellingPrice.min);
const originalPrice = computed(() => properties.product.listPrice.min);
const url = computed(() => properties.product.link);

const type = computed(() => getStringValues('type')[0]);
const productId = computed(() => getStringValues('id')[0]);
const sku = computed(() => getStringValues('sku')[0]);
const promotion = computed<PromotionData | undefined>(() => {
    const value = getStringValues('promotion')[0];

    return value ? JSON.parse(value) : undefined;
});

const productBadges = computed<ElevateProductBadge[]>(() => {
    const value = getStringValues('productBadges')[0];

    return value ? JSON.parse(value) : [];
});

const textProductBadges = computed<ElevateProductBadge[]>(() => productBadges.value.filter((badge) => !badge.icon));

const deliveryEstimateDays = computed(() => (properties.product.custom?.delivery_estimate_days
    ? Number.parseInt((properties.product.custom?.delivery_estimate_days[0].id), 10)
    : 0));

const clubPrice = computed<number | undefined>(() => {
    if (properties.product.custom?.clubPrice) {
        return Number(properties.product.custom?.clubPrice[0].id);
    }

    return properties.product.variants
        .map((variant) => variant.custom?.clubPrice?.[0]?.id)
        .filter((value): value is string => !!value)
        .map(Number)
        .sort((a, b) => a - b)
        .at(0);
});

const { notifyClick } = useElevate();

async function onClick() {
    await notifyClick(properties.product.ticket);
}

const gaProduct = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    item_id: sku.value,
    item_name: properties.product.title,
    item_unique_id: productId.value,
    ...properties.product.brand ? { item_brand: properties.product.brand } : {},
    price: properties.product.sellingPrice.min,
    quantity: 1,
    /* eslint-enable @typescript-eslint/naming-convention */
}));

/** Wishlist logic */
async function addToWishlist() {
    if (!productId.value) {
        return;
    }

    gtm?.trackEvent({
        /* eslint-disable @typescript-eslint/naming-convention */
        event: 'add_to_wishlist',
        _clear: true,
        ecommerce: {
            currencyCode: 'EUR',
            value: gaProduct.value.price,
            items: [gaProduct.value],
        },
        /* eslint-enable @typescript-eslint/naming-convention */
    });

    try {
        await wishlist.addProduct(productId.value);

        $eventbus.emit('product-added-to-wishlist', {
            productId: properties.product.key,
        });
    } catch {
        toast.add('warning', `${tg('toast.wishlist-warning')}`);
    }
}
</script>

<i18n lang="json">
{
    "et": {
        "product-part-payment": "Maksa osade kaupa ",
        "product-part-payment-prefix": "al.",
        "product-part-payment-suffix": "/kuu"
    },
    "fi": {
        "product-part-payment": "Tai maksa erissä ",
        "product-part-payment-prefix": "alk.",
        "product-part-payment-suffix": "/kk"
    }
}
</i18n>

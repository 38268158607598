/**
 * Composable that sets head elements that should be present on all pages.
 * NOTE: This should only include stuff that can be re-defined with a subsequent call and isn't additive, so that you can override them per-page later. Define other composables for head elements that can't be introduced multiple times.
 */
export function useDefaultDynamicHead({
    includeQueryInCanonicalUrl = true,
}: {
    includeQueryInCanonicalUrl?: boolean
} = {}) {
    const config = useRuntimeConfig();
    const route = useRoute();

    const path = computed(() => (includeQueryInCanonicalUrl ? route.fullPath : route.path));

    // useHead merges its parameters into the current head so it can be called from elsewhere too.
    useHead(() => ({
        link: [
            {
                rel: 'canonical',
                href: `${config.public.appUrl + path.value}`,
            },
        ],
    }));
}

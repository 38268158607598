import type { ISbRichtext } from '@storyblok/js';

export default function isFilledRichText(value: ISbRichtext) {
    if (!value || !value.content || value.content.length === 0) {
        return false;
    }

    // Filled if the first element isn't a paragraph or it's a paragraph that has content. If users manually empty a rich text field it will contain an empty paragraph, that should be considered empty.
    return value.content[0].type !== 'paragraph' || !!value.content[0].content;
}

<template>
    <h-popover class="relative z-20">
        <v-header-button id="wishlist"
            :aria-label="$t('aria.open', { name: $t('wishlist')})"
            :badge="products.length"
            :title="t('aria.open', { name: t('wishlist')})"
            class="rounded transition-colors duration-150
            focus-visible:rounded-2xs focus-visible:outline-2 focus-visible:outline-white
            focus-visible:ring-4 focus-visible:ring-blue-600"
            @click="isOpen = true">
            <v-icon icon="heart"
                class="text-xl text-[color:var(--color)]"
                :style="{
                    '--color': fontColor
                }"
            />
        </v-header-button>

        <transition-group
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-300 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <h-popover-overlay v-if="wishlistProductId"
                key="wishlist-overlay"
                static
                class="fixed inset-0 bg-black/30"
                @click="wishlistProductId = undefined" />

            <h-popover-panel v-if="wishlistProductId"
                key="wishlist-panel"
                static
                @mouseover="stop"
                @mouseleave="start"
                @focusin="stop"
                @focusout="start">
                <v-header-wishlist-small :cart="wishListData"
                    :product-id="wishlistProductId"
                    class="fixed inset-x-0 top-0 z-10 mx-auto
                        bg-white text-[color:var(--color)] sm:absolute
                        sm:left-auto sm:top-12 sm:mx-0"
                    :style="{
                        '--color': fontColor
                    }"
                    @close="wishlistProductId = undefined" />
            </h-popover-panel>
        </transition-group>
    </h-popover>

    <lazy-v-modal :is-open="isOpen"
        class="bg-white"
        @close="isOpen = false">
        <v-modal-header @close="isOpen = false">
            {{ t('wishlist-title') }}
        </v-modal-header>

        <div class="px-sm pb-20 md:px-lg">
            <div v-if="products.length > 0">
                <template v-for="product in products"
                    :key="product.id">
                    <v-header-wishlist-product :product="product" />

                    <hr class="my-md border-gray-100">
                </template>
            </div>

            <div v-else
                class="flex flex-col items-center pb-20">
                <p>{{ t('wishlist-empty') }}</p>

                <v-icon class="mt-md text-10xl text-red-500"
                    icon="store" />
            </div>
        </div>
    </lazy-v-modal>
</template>

<script lang="ts" setup>
import { useQuery } from '@urql/vue';
import { useTimeoutFn } from '@vueuse/core';
import { type ViewCartQuery, type ViewCartQueryVariables, ViewCartDocument } from '~~/graphql';

defineProps<{
    fontColor?: string
}>();

const router = useRouter();
const isOpen = ref(false);

router.beforeEach(() => {
    isOpen.value = false;
});

const { id: wishlistId } = useCart(true);

const viewCartVariables = computed(() => ({
    id: wishlistId.value ?? '',
}));

const { data } = useQuery<ViewCartQuery, ViewCartQueryVariables>({
    query: ViewCartDocument,
    variables: viewCartVariables,
    pause: computed(() => !wishlistId.value || wishlistId.value.length === 0),
});

const wishListData = computed(() => {
    if (!data.value || data.value.cart.__typename !== 'Cart') {
        return undefined;
    }

    return data.value.cart;
});

const products = computed(() => wishListData.value?.products || []);
const wishlistProductId = ref<string>();
const { $eventbus } = useNuxtApp();

const { start, stop } = useTimeoutFn(() => {
    wishlistProductId.value = undefined;
}, 3000, { immediate: false });

$eventbus.on('product-added-to-wishlist', ({ productId }) => {
    wishlistProductId.value = productId;
    start();
});

const { t } = useI18n();
</script>

<i18n lang="json">
{
    "et": {
        "wishlist-empty": "Soovinimekiri on tühi",
        "wishlist-title": "Soovinimekiri"
    },
    "fi": {
        "wishlist-empty": "Muistilistasi on tyhjä",
        "wishlist-title": "Muistilista"
    }
}
</i18n>

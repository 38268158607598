<template>
    <!-- eslint-disable vue/no-v-html -->
    <div class="break-words"
        :class="{
            'prose': !noProse,
        }"
        v-html="content">
    </div>
</template>

<script setup lang="ts">
import type { ISbRichtext } from '@storyblok/js';

const properties = defineProps<{
    document: ISbRichtext | string
    noProse?: boolean
}>();

const content = computed(() => renderCustomRichText(properties.document));
</script>

<template>
    <button type="button"
        :title="t('choose-outlet')"
        class="order-3 flex flex-1 items-center
            justify-center overflow-hidden bg-[color:var(--background-color)]
            text-[color:var(--color)] outline-blue-600 transition-all duration-300 focus-visible:outline-2 md:order-none
                md:flex-none md:justify-end"
        :class="{
            'h-0 basis-0': isHeaderSticky,
            'h-10 basis-10': !isHeaderSticky,
        }"
        :style="{
            '--background-color': infoBarBackground,
            '--color': infoBarfontColor
        }"
        @click="isOpen = true">
        <v-icon icon="shop"
            class="mr-2 text-[20px]"></v-icon>

        <div class="flex items-center space-x-xs text-sm outline-none">
            <span v-if="selectedStory && daysFromTable && openToday">
                <strong>{{ selectedStory.name }}</strong>
                |  {{ currentDayUpperCase }} {{ openToday }}
            </span>

            <span v-else-if="selectedStory">
                <strong>{{ selectedStory.name }}</strong>
                | {{ selectedStory.content.hours?.content?.[0]?.content?.[0]?.text }}
            </span>

            <span v-else>
                {{ t('choose-outlet') }}
            </span>

            <v-icon icon="chevron-down"
                class="text-lg"></v-icon>
        </div>
    </button>

    <lazy-v-header-shop-picker v-model="selectedStory"
        :is-open="isOpen"
        :outlets="outlets"
        @close="isOpen = false" />
</template>

<script setup lang="ts">
import type { ISbStoryData } from '@storyblok/js';
import type { IHoursTable } from '~~/types';
import { addMonths } from 'date-fns';

const properties = defineProps<{
    isHeaderSticky: boolean
    backgroundColor?: string
    fontColor?: string
}>();

const infoBarBackground = computed<string | undefined>(() => (properties.backgroundColor === undefined
    ? '#EF1C35'
    : properties.backgroundColor));
const infoBarfontColor = computed<string | undefined>(() => (properties.fontColor === undefined
    ? '#FFF'
    : properties.fontColor));

const { t } = useI18n();
const outlets = useStoryblokOutlets();
const isOpen = ref(false);

const { $eventbus } = useNuxtApp();

$eventbus.on('open-shop-picker', () => {
    isOpen.value = true;
});

const shopCookie = useCookie('selected-shop', {
    expires: addMonths(new Date(), 1),
});

const selectedStory = computed<ISbStoryData | undefined>({
    get() {
        return shopCookie.value ? outlets.value.find((outlet) => outlet.uuid === shopCookie.value) : undefined;
    },
    set(value) {
        shopCookie.value = value?.uuid ?? null;
    },
});

const currentDay = new Intl.DateTimeFormat(t('date-locale'), { weekday: 'long' }).format();
const currentDayUpperCase = currentDay.charAt(0).toUpperCase() + currentDay.slice(1);
const daysFromTable = computed<IHoursTable | undefined>(() => selectedStory.value?.content.hours_table);

const openToday = computed<string | undefined>(() => {
    // Check if data is defined and if tbody exists in data
    if (daysFromTable.value && Array.isArray(daysFromTable.value.tbody)) {
        // Iterate through the tbody array to find the matching day
        for (const row of daysFromTable.value.tbody) {
        // Check if the row has a body and the first item is not undefined
            if (
                row.body
                && Array.isArray(row.body)
                && row.body.length > 0
                && row.body[0] !== undefined
                && row.body[0].value === currentDayUpperCase
            ) {
                // Day matches, return the body array with opening hours
                return row.body[1].value;
            }
        }
    }

    // If no match found or if data is undefined, return null or an appropriate response
    return undefined;
});
</script>

<i18n lang="json">
{
    "fi": {
        "date-locale": "fi-FI",
        "choose-outlet": "Valitse myymälä"
    },
    "et": {
        "date-locale": "et-ET",
        "choose-outlet": "Valige kauplus"
    }
}
</i18n>

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208c13.3 0 24 10.7 24 24s-10.7 24-24 24C114.6 512 0 397.4 0 256S114.6 0 256 0s256 114.6 256 256v28c0 50.8-41.2 92-92 92-31.1 0-58.7-15.5-75.3-39.2-22 24.1-53.6 39.2-88.7 39.2-66.3 0-120-53.7-120-120s53.7-120 120-120c28.8 0 55.2 10.1 75.8 27 4.3-6.6 11.7-11 20.2-11 13.3 0 24 10.7 24 24v108c0 24.3 19.7 44 44 44s44-19.7 44-44v-28c0-114.9-93.1-208-208-208m72 208a72 72 0 1 0-144 0 72 72 0 1 0 144 0" }, null, -1)
  ])))
}
export default { render: render }
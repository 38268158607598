<template>
    <v-modal :is-open="isOpen"
        class="bg-white"
        @close="$emit('close')">
        <v-modal-header @close="$emit('close')">
            {{ t('login') }}
        </v-modal-header>

        <div class="my-md h-max bg-gray-50 p-0">
            <v-login-ingress></v-login-ingress>

            <div v-if="!isAuth0LockLoaded"
                class="px-sm md:px-md">
                <v-button theme="black"
                    class="my-sm w-full px-sm md:px-md"
                    :aria-label="t('login')"
                    @click="doLogin">
                    {{ t('login') }}
                </v-button>
            </div>

            <div id="auth0-lock-container"></div>
        </div>
    </v-modal>
</template>

<script lang="ts" setup>
import { watchDebounced } from '@vueuse/core';

const properties = defineProps<{
    isOpen: boolean
}>();

defineEmits<{
    (event: 'close'): void
}>();

const { t } = useI18n();
const {
    showLoginForm,
    hideLoginForm,
    isAuth0LockLoaded,
    login,
} = useAuth();

watchDebounced(() => properties.isOpen, (value) => {
    if (value) {
        showLoginForm();
    } else {
        hideLoginForm();
    }
}, { debounce: 50 });

async function doLogin() {
    await login(window.location.pathname);
}
</script>

<style lang="scss">
body .auth0-lock.auth0-lock {
    @apply font-sans;

    .auth0-lock-center {
        @apply p-6;
    }

    .auth0-lock-widget {
        @apply w-full;
    }

    .auth0-lock-header {
        @apply hidden;
    }

    .auth0-lock-content-body-wrapper {
        @apply rounded-t-[5px] overflow-hidden;
    }

    .auth0-lock-tabs {
        @apply text-base;

        li {
            @apply pt-2 pb-2;
        }

        li span {
            @apply text-black;
        }

        li.auth0-lock-tabs-current span {
            @apply text-black font-bold;
        }
    }

    .auth0-lock-submit {
        @apply text-base font-bold;
    }

    .auth0-lock-cred-pane-internal-wrapper {
        @apply h-auto;
    }
}
</style>

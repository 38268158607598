import {
    type ViewCartQuery,
} from '~~/graphql';

type CartProduct = Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>['products'][0];

/**
 * Computes the media items for the cart product.
 *
 * This function combines the listing image of the cart product and the media items of the parent product.
 * If the cart product has a listing image, it is included in the result.
 * Additionally, if the cart product has a listing image, the media items of the parent product are included
 * with modifications: they are marked as default and their positions are incremented by 100.
 *
 * @returns {Array} An array of media items for the cart product.
 */

export function useProductMedias(cartProduct: CartProduct) {
    const cartProductListingImage = cartProduct.product.listingImage;
    const parentProductMedias = cartProduct.product.parentProduct?.medias ?? [];

    return [
        ...(cartProductListingImage ? [cartProductListingImage] : []),
        ...(cartProductListingImage ? parentProductMedias.map((item) => ({
            ...item,
            isDefault: true,
            position: item.position + 100,
        })) : parentProductMedias),
    ];
}

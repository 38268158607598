/**
 * Adjusts the brightness of a hex color by a given percentage.
 * If the color is dark, it will be darkened. If the color is light, it will be lightened.
 * Note that this does not apply to true black or white colors (#000000 and #FFFFFF).
 *
 * @param {string} color - The hex color code to be adjusted (e.g., "#RRGGBB").
 * @param {number} percentage - The percentage by which to adjust the brightness.
 * @returns {string} - The modified hex color code.
 */
export function useModifyColor(color: string, percentage: number) {
    const hex = color.replace('#', '');
    const r = Number.parseInt(hex.slice(0, 2), 16);
    const g = Number.parseInt(hex.slice(2, 4), 16);
    const b = Number.parseInt(hex.slice(4, 6), 16);

    const isDarkColor = (r * 0.299 + g * 0.587 + b * 0.114) < 128;

    const amount = Math.floor((255 * percentage) / 100);

    let newR;
    let newG;
    let newB;

    if (isDarkColor) {
        newR = Math.max(0, r - amount);
        newG = Math.max(0, g - amount);
        newB = Math.max(0, b - amount);
    } else {
        newR = Math.min(255, r + amount);
        newG = Math.min(255, g + amount);
        newB = Math.min(255, b + amount);
    }

    const modifiedColor = `#${newR.toString(16).padStart(2, '0')}`
            + `${newG.toString(16).padStart(2, '0')}`
            + `${newB.toString(16).padStart(2, '0')}`;
    return modifiedColor;
}

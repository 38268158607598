import { whenever } from '@vueuse/core';
import { useGtm } from '@gtm-support/vue-gtm';
import { useQuery } from '@urql/vue';
import { decodeJwt } from 'jose';
import { type ViewMeQuery, ViewMeDocument } from '~~/graphql';
import { useProfile } from '~/store/profile';

export function useCustomerLogin() {
    const gtm = useGtm();
    const tokenCookie = useCookie('auth0.token');
    const profileStore = useProfile();

    const { executeQuery } = useQuery<ViewMeQuery>({
        query: ViewMeDocument,
        pause: true,
    });

    async function getProfile() {
        const { data } = await executeQuery();

        if (tokenCookie.value && data.value?.me) {
            profileStore.setProfile(data.value.me);

            const tokenData = decodeJwt(tokenCookie.value);

            gtm?.trackEvent({
                event: 'customer_data',
                name: data.value.me.name,
                email: data.value.me.email,
                voyadoContactId: tokenData['https://api.masku.com/voyado_contact_id'],
            });
        }
    }

    onMounted(async () => {
        await getProfile();
    });

    whenever(() => tokenCookie.value, async () => {
        await getProfile();
    });

    return {
        profile: profileStore,
        token: tokenCookie,
    };
}

<template>
    <div>
        <v-header-button :as="!isLoggedIn ? 'a' : undefined"
            :aria-label="isLoggedIn ? $t('aria.open', { name: $t('profile-page-url')}) : $t('login')"
            :to="isLoggedIn ? { name: 'account' } : undefined"
            :href="!isLoggedIn ? '/account' : undefined"
            :title="isLoggedIn ? $t('aria.open', { name: $t('profile-page-url')}) : $t('login')"
            class="rounded p-1 transition-colors duration-150
                focus-visible:rounded-2xs focus-visible:outline-2 focus-visible:outline-white
                focus-visible:ring-4 focus-visible:ring-blue-600"
            @click="onClick">
            <v-icon icon="user"
                class="text-xl text-[color:var(--color)]"
                :style="{
                    '--color': fontColor
                }" />

            <transition
                enter-active-class="transition duration-300 ease-out"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition duration-300 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <span v-if="isLoggedIn"
                    class="absolute bottom-1 right-0 flex size-4 items-center justify-center rounded-full
                        bg-black text-xs text-white">
                    <v-icon icon="check" />
                </span>
            </transition>
        </v-header-button>

        <lazy-v-header-profile :is-open="isProfileOpen"
            @close="isProfileOpen = false" />
    </div>
</template>

<script lang="ts" setup>
const properties = defineProps<{
    fontColor?: string
}>();

const isProfileOpen = ref(false);
const { profile, token } = useCustomerLogin();
const { $eventbus } = useNuxtApp();

$eventbus.on('open-profile', () => {
    isProfileOpen.value = true;
});

const isLoggedIn = computed(() => !!token.value && !!profile.profile);

function onClick(event: Event) {
    if (!isLoggedIn.value) {
        event.preventDefault();

        isProfileOpen.value = true;
    }
}

$eventbus.on('open-login-modal', () => {
    isProfileOpen.value = true;
});

const fontColor = computed<string | undefined>(() => (properties.fontColor === undefined
    ? '#FFF'
    : properties.fontColor));
</script>

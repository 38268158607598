<template>
    <component :is="tag"
        :id="blok._uid"
        :to="bannerLink"
        :title="blok.bg_image.alt"
        class="relative block aspect-[16/9] xl:aspect-[2.35/1]"
        @click="addPromotionClick(promo)">
        <div v-if="blok.button.cached_url || blok.button.url"
            class="absolute inset-x-0 bottom-4 z-20 flex justify-center sm:bottom-16">
            <v-button :to="blok.button.url ? blok.button.url : blok.button.cached_url "
                :aria-label="blok.button_text"
                theme="gray">
                {{ blok.button_text }}
            </v-button>
        </div>

        <nuxt-picture v-if="blok.bg_image?.filename"
            :src="blok.bg_image.filename"
            :alt="altText"
            class="absolute inset-0"
            :preload="index === 0"
            :img-attrs="{
                class: 'w-full h-full object-cover',
            }"
            width="1536"
            height="864"
            provider="storyblok"
            sizes="xs:100vw sm:640px md:768px lg:1024px xl:1280px 2xl:1536px" />
    </component>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';
import { NuxtLink } from '#components';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-cta' | 'sb-cta-big'
        | 'sb-cta-button' | 'sb-cta-content' | 'sb-cta-image' | 'sb-cta-narrow' | 'sb-cta-countdown' }>
    index?: number
}>();

const { format: formatSbUrl } = useSbUrl();
const bannerLink = computed(() => formatSbUrl(properties.blok.whole_element_cta_link?.cached_url));

const tag = computed(() => {
    if (bannerLink.value) {
        return NuxtLink;
    }
    return 'div';
});
const route = useRoute();
const { addPromotionClick } = usePromotion();

function convertString(string: string) {
    const words = string.split('-');
    const convertedString = words.map(((word) => word.charAt(0).toUpperCase() + word.slice(1))).join(' ');
    return convertedString;
}

const promo = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    creative_name: convertString(properties.blok.component),
    creative_slot: `${properties.blok.component} / ${properties.index}`, // component name / index
    // eslint-disable-next-line no-underscore-dangle
    promotion_id: properties.blok._uid,
    promotion_name: properties.blok.name,
    source_url: route.fullPath,
    target_url: bannerLink.value,
    /* eslint-enable @typescript-eslint/naming-convention */
}));

const altText = computed(() => properties.blok.bg_image.alt ?? properties.blok.title);
</script>

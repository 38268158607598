<template>
    <div class="flex space-x-sm">
        <nuxt-link :to="formatSbUrl(`/${$t('storyblok.product')}/${cartProduct.product.seo.slug}`)"
            :aria-label="cartProduct.product.name"
            class="relative flex size-28 shrink-0 items-center justify-center overflow-hidden rounded p-2">
            <div class="absolute inset-0 rounded bg-gray-500/5"></div>

            <nuxt-picture v-if="medias.length > 0"
                :src="medias[0].url"
                height="112"
                loading="lazy"
                width="112"
                class="size-full"
                :class="{
                    'opacity-50': isProductOutOfStock
                }"
                sizes="sm:100vw md:555px lg:725px xl:896px 2xl:1067px"
                :alt="cartProduct.product.name"
                :img-attrs="{
                    class: 'h-full w-full object-contain',
                }" />
        </nuxt-link>

        <div class="flex-1">
            <div class="mb-4 flex items-center justify-between space-x-sm">
                <nuxt-link :to="formatSbUrl(`/${$t('storyblok.product')}/${cartProduct.product.seo.slug}`)"
                    :aria-label="cartProduct.product.name"
                    class="group">
                    <v-subtitle class="font-bold group-hover:underline">
                        {{ cartProduct.product.title || cartProduct.product.name }}
                    </v-subtitle>

                    <p v-if="cartProduct.product.specification"
                        class="line-clamp-2 leading-tight text-gray-500"
                        :title="cartProduct.product.specification">
                        {{ cartProduct.product.specification }}
                    </p>
                </nuxt-link>

                <v-cart-button @click="remove">
                    <v-icon icon="trash" />
                </v-cart-button>
            </div>

            <div class="flex items-center space-x-4">
                <v-quantity v-model="quantity"
                    :step="cartProduct.product.cart.multiplier ?? undefined"
                    :min="cartProduct.product.cart.minAmount ?? undefined"
                    :max="cartProduct.product.cart.maxAmount ?? undefined" />

                <div class="flex items-baseline space-x-2">
                    <span class="font-prenton-cond text-xl"
                        :class="{
                            'text-red-500': cartProduct.product.pricing.originalPrice
                                && cartProduct.unitPrice.amount !== cartProduct.product.pricing.originalPrice?.amount
                        }">
                        {{ formatCurrency(cartProduct.price.amount, { maximumFractionDigits: 2 }) }}
                    </span>

                    <del
                        v-if="cartProduct.product.pricing.originalPrice
                            && cartProduct.unitPrice.amount !== cartProduct.product.pricing.originalPrice?.amount"
                        class="text-xs text-gray-500 line-through">
                        {{ formatCurrency(originalPrice, { maximumFractionDigits: 2 }) }}
                    </del>
                </div>
            </div>

            <div v-if="isProductOutOfStock || cartProduct.product.state === ProductState.Draft"
                class="my-sm flex flex-row items-center justify-between rounded-xs border
                    border-gray-50 bg-gray-50 px-sm py-xs">
                <p class="font-bold">{{ t('out-of-stock') }}</p>

                <v-icon icon="info-circle"
                    class="text-xl font-bold text-red-500"></v-icon>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { watchDebounced } from '@vueuse/core';
import { useProductMedias } from '~/composables/use-product-medias';
import {
    ProductInventoryState,
    type ViewCartQuery,
    ProductState,
} from '~~/graphql';

const properties = defineProps<{
    cartProduct: Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>['products'][0]
}>();
const cart = useCart();
const { t } = useI18n();

const quantity = ref(properties.cartProduct.quantity);
const { format: formatSbUrl } = useSbUrl();
const { format: formatCurrency } = useCurrency();

const isProductOutOfStock = computed(() => (
    properties.cartProduct.product.inventory.state === ProductInventoryState.OutOfStock
));

const originalPrice = computed(() => {
    const amount = Number(properties.cartProduct.product.pricing.originalPrice?.amount ?? 0);

    return amount * properties.cartProduct.quantity;
});

const medias = computed(() => useProductMedias(properties.cartProduct));

watchDebounced(quantity, async () => {
    if (quantity.value <= 0) {
        quantity.value = properties.cartProduct.quantity;

        return;
    }

    await cart.updateProduct(properties.cartProduct.id, quantity.value);
}, { debounce: 300 });

/** Remove product from cart */
async function remove() {
    await cart.removeProduct(properties.cartProduct.id);
}
</script>

<i18n lang="json">
{
    "et": {
        "out-of-stock": "Hetkel laost otsas"
    },
    "fi": {
        "out-of-stock": "Tuote ei ole ostettavissa"
    }
}
</i18n>

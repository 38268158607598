<template>
    <div class="mx-sm rounded bg-gray-50 px-md py-sm">
        <div v-if="isLoading"
            class="flex flex-col items-center">
            <v-spinner />

            <p class="pt-sm">Ladataan myymälän tietoja..</p>
        </div>

        <div v-else>
            <v-h6 class="mb-md">
                {{ hasSelection ? t('outlet-selected') : t('outlet-closest') }}
            </v-h6>

            <p class="mb-xs text-base font-bold">{{ outlet.name }}</p>

            <div v-if="hoursBody.tbody.length > 0 || outlet.content.hours"
                class="mb-sm flex flex-col items-start space-y-sm">
                <dl v-if="hoursBody"
                    class="grid grid-cols-2 gap-x-lg text-sm">
                    <template v-for="(tr, index) in hoursBody.tbody"
                        :key="index">
                        <dt :class="{
                            'font-bold': tr.body[0].value === currentDayUpperCase
                        }">
                            {{ tr.body[0].value }}
                        </dt>

                        <dd :class="{
                            'font-bold': tr.body[0].value === currentDayUpperCase
                        }">
                            {{ tr.body[1].value }}
                        </dd>
                    </template>
                </dl>

                <v-storyblok-rich-text
                    class="text-sm"
                    no-prose
                    :document="outlet.content.hours" />
            </div>

            <div class="mb-sm space-y-xs">
                <div v-if="outlet.content.phone && outlet.content.phone.length > 1"
                    class="flex items-center space-x-xs">
                    <v-icon icon="call" />

                    <a :href="`tel:${outlet.content.phone}`"
                        class="hover:underline">
                        {{ outlet.content.phone }}
                    </a>
                </div>

                <div v-if="outlet.content.email && outlet.content.email.length > 1"
                    class="flex items-center space-x-xs">
                    <v-icon icon="at" />

                    <a :href="`mailto: ${outlet.content.email}`"
                        class="hover:underline">
                        {{ outlet.content.email }}
                    </a>
                </div>

                <p v-if="outlet.content.location"
                    class="flex items-center space-x-xs">
                    <v-icon icon="location"
                        class="text-lg"></v-icon>

                    <nuxt-link :to="routeLink"
                        target="_blank"
                        class="hover:underline">
                        {{ outlet.content.address }}
                    </nuxt-link>
                </p>
            </div>

            <p>
                <nuxt-link :to="formatSbUrl(outlet.full_slug)"
                    class="mt-xs underline"
                    @click="close">
                    {{ t('outlet-info') }}
                </nuxt-link>
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ISbStoryData } from '@storyblok/js';

const properties = defineProps<{
    outlet: ISbStoryData
    hasSelection?: boolean
}>();

const emit = defineEmits<{
    (event: 'close'): void
}>();

const { t } = useI18n();

const hoursBody = computed(() => properties.outlet.content.hours_table);
const { format: formatSbUrl } = useSbUrl();

/** Get current day in intl date format and format to uppercase */

const currentDayUpperCase = computed(() => {
    const currentDay = new Intl.DateTimeFormat(t('date-locale'), { weekday: 'long' }).format();
    return currentDay.charAt(0).toUpperCase() + currentDay.slice(1);
});

const routeLink = computed(() => (
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(properties.outlet.content.location)}`
));

const isLoading = ref(false);

watch(() => properties.outlet, (newValue, oldValue) => {
    isLoading.value = true;
    if (newValue && oldValue && newValue.id !== oldValue.id) {
        setTimeout(() => {
            isLoading.value = false;
        }, 1000);
    }
});

function close() {
    emit('close');
}
</script>

<i18n lang="json">
{
    "et": {
        "date-locale": "et-ET",
        "outlet-closest": "Lähim kauplus",
        "outlet-selected": "Valitud kauplus",
        "address": "Aadress",
        "phone-number": "Telefoni number",
        "outlet-hours": "Lahtiolekuajad",
        "outlet-location": "Kaupluse asukoht",
        "product-count": "toodet",
        "outlet-products": "Kaupluse eripakkumised",
        "outlet-info": "Lisainfo kaupluse lehel"
    },
    "fi": {
        "date-locale": "fi-FI",
        "outlet-closest": "Lähin myymäläsi",
        "outlet-selected": "Myymäläsi",
        "address": "Osoite",
        "phone-number": "Puhelinnumero",
        "outlet-hours": "Avoinna",
        "outlet-location": "Myymälän sijanti",
        "product-count": "tuotetta",
        "outlet-products": "Myymälän outlet tuotteet",
        "outlet-info": "Lisätietoa myymälän sivuilta"
    }
}
</i18n>

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 48 48"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g clip-path=\"url(#a)\"><path fill=\"#FF0302\" d=\"M47.044 12.37A6.03 6.03 0 0 0 42.8 8.1c-3.752-1.01-18.752-1.01-18.752-1.01s-15 0-18.753 1.01a6.03 6.03 0 0 0-4.244 4.27C.048 16.14.048 24 .048 24s0 7.86 1.003 11.63a6.03 6.03 0 0 0 4.244 4.27c3.753 1.01 18.753 1.01 18.753 1.01s15 0 18.752-1.01a6.03 6.03 0 0 0 4.244-4.27C48.048 31.86 48.048 24 48.048 24s0-7.86-1.004-11.63\"></path><path fill=\"#FEFEFE\" d=\"M19.139 31.137V16.863L31.684 24z\"></path></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h48v48H0z\"></path></clipPath></defs>", 2)
  ])))
}
export default { render: render }